// button specific colors

$BUTTON_COLOR: var(--tct-btn-secondary-font-color, var(--t-button-default-font-color, #2E2E2E));
$BUTTON_COLOR_SELECTED: var(--tct-btn-secondary-hover-font-color, var(--t-button-default-hover-font-color, #FFFFFF));
$BUTTON_TEXT_SHADOW: none;
$BUTTON_TEXT_SHADOW_SELECTED: none;
$BUTTON_BORDER: var(--tct-btn-secondary-border-color, transparent);
$BUTTON_BORDER_HOVER: var(--tct-btn-secondary-hover-border-color, var(--tct-btn-secondary-border-color, transparent));
$BUTTON_BORDER_SELECTED: $BUTTON_BORDER_HOVER;
$BUTTON_BACKGROUND: var(--tct-btn-secondary-bg, var(--t-button-default-bg, #CCCCCC));
$BUTTON_BACKGROUND_HOVER: var(--tct-btn-secondary-hover-bg, var(--t-button-default-hover-bg, #2E2E2E));
$BUTTON_BACKGROUND_SELECTED: $BUTTON_BACKGROUND_HOVER;
$BUTTON_BOX_SHADOW: var(--tct-btn-secondary-box-shadow, 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24));
$BUTTON_BOX_SHADOW_2: none;
$BUTTON_BOX_SHADOW_HOVER: var(--tct-btn-secondary-hover-box-shadow, 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23));
$BUTTON_BOX_SHADOW_HOVER_2: none;
$BUTTON_BOX_SHADOW_SELECTED: $BUTTON_BOX_SHADOW_HOVER;

$BUTTON_EMPHASIS_COLOR: var(--tct-btn-primary-font-color, var(--t-button-primary-font-color, #FFFFFF));
$BUTTON_EMPHASIS_COLOR_SELECTED: var(--tct-btn-primary-hover-font-color, var(--t-button-primary-hover-font-color, #FFFFFF));
$BUTTON_EMPHASIS_TEXT_SHADOW: none;
$BUTTON_EMPHASIS_TEXT_SHADOW_SELECTED: none;
$BUTTON_EMPHASIS_BORDER: var(--tct-btn-primary-border-color, transparent);
$BUTTON_EMPHASIS_BORDER_HOVER: var(--tct-btn-primary-hover-border-color, var(--tct-btn-primary-border-color, transparent));
$BUTTON_EMPHASIS_BORDER_SELECTED: $BUTTON_EMPHASIS_BORDER_HOVER;
$BUTTON_EMPHASIS_BACKGROUND: var(--tct-btn-primary-bg, var(--t-button-primary-bg, #2E2E2E));
$BUTTON_EMPHASIS_BACKGROUND_HOVER: var(--tct-btn-primary-hover-bg, var(--t-button-primary-hover-bg, #4A4A4A));
$BUTTON_EMPHASIS_BACKGROUND_SELECTED: $BUTTON_EMPHASIS_BACKGROUND_HOVER;
$BUTTON_EMPHASIS_BOX_SHADOW: var(--tct-btn-primary-box-shadow, 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24));
$BUTTON_EMPHASIS_BOX_SHADOW_2: none;
$BUTTON_EMPHASIS_BOX_SHADOW_HOVER: var(--tct-btn-primary-hover-box-shadow, 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23));
$BUTTON_EMPHASIS_BOX_SHADOW_HOVER_2: none;
$BUTTON_EMPHASIS_BOX_SHADOW_SELECTED: $BUTTON_EMPHASIS_BOX_SHADOW_HOVER;

$BUTTON_SECONDARY_COLOR: $BUTTON_COLOR;
$BUTTON_SECONDARY_COLOR_SELECTED: $BUTTON_COLOR_SELECTED;
$BUTTON_SECONDARY_TEXT_SHADOW: $BUTTON_TEXT_SHADOW;
$BUTTON_SECONDARY_TEXT_SHADOW_SELECTED: $BUTTON_TEXT_SHADOW_SELECTED;
$BUTTON_SECONDARY_BORDER: $BUTTON_BORDER;
$BUTTON_SECONDARY_BORDER_HOVER: $BUTTON_BORDER_HOVER;
$BUTTON_SECONDARY_BORDER_SELECTED: $BUTTON_BORDER_SELECTED;
$BUTTON_SECONDARY_BACKGROUND: $BUTTON_BACKGROUND;
$BUTTON_SECONDARY_BACKGROUND_HOVER: $BUTTON_BACKGROUND_HOVER;
$BUTTON_SECONDARY_BACKGROUND_SELECTED: $BUTTON_BACKGROUND_SELECTED;
$BUTTON_SECONDARY_BOX_SHADOW: $BUTTON_BOX_SHADOW;
$BUTTON_SECONDARY_BOX_SHADOW_2: $BUTTON_BOX_SHADOW_2;
$BUTTON_SECONDARY_BOX_SHADOW_HOVER: $BUTTON_BOX_SHADOW_HOVER;
$BUTTON_SECONDARY_BOX_SHADOW_HOVER_2: $BUTTON_BOX_SHADOW_HOVER_2;
$BUTTON_SECONDARY_BOX_SHADOW_SELECTED: $BUTTON_BOX_SHADOW_SELECTED;

$BUTTON_PRIMARY_COLOR: $BUTTON_COLOR;
$BUTTON_PRIMARY_COLOR_SELECTED: $BUTTON_COLOR_SELECTED;
$BUTTON_PRIMARY_TEXT_SHADOW: $BUTTON_TEXT_SHADOW;
$BUTTON_PRIMARY_TEXT_SHADOW_SELECTED: $BUTTON_TEXT_SHADOW_SELECTED;
$BUTTON_PRIMARY_BORDER: $BUTTON_BORDER;
$BUTTON_PRIMARY_BORDER_HOVER: $BUTTON_BORDER_HOVER;
$BUTTON_PRIMARY_BORDER_SELECTED: $BUTTON_BORDER_SELECTED;
$BUTTON_PRIMARY_BACKGROUND: $BUTTON_BACKGROUND;
$BUTTON_PRIMARY_BACKGROUND_HOVER: $BUTTON_BACKGROUND_HOVER;
$BUTTON_PRIMARY_BACKGROUND_SELECTED: $BUTTON_BACKGROUND_SELECTED;
$BUTTON_PRIMARY_BOX_SHADOW: $BUTTON_BOX_SHADOW;
$BUTTON_PRIMARY_BOX_SHADOW_2: $BUTTON_BOX_SHADOW_2;
$BUTTON_PRIMARY_BOX_SHADOW_HOVER: $BUTTON_BOX_SHADOW_HOVER;
$BUTTON_PRIMARY_BOX_SHADOW_HOVER_2: $BUTTON_BOX_SHADOW_HOVER_2;
$BUTTON_PRIMARY_BOX_SHADOW_SELECTED: $BUTTON_BOX_SHADOW_SELECTED;

$BUTTON_DANGER_COLOR: #ffffff;
$BUTTON_DANGER_COLOR_SELECTED: #ffffff;
$BUTTON_DANGER_TEXT_SHADOW: #b63141;
$BUTTON_DANGER_TEXT_SHADOW_SELECTED: #721f29;
$BUTTON_DANGER_BORDER: #b63141;
$BUTTON_DANGER_BORDER_HOVER: #9f2b39;
$BUTTON_DANGER_BORDER_SELECTED: #882531;
$BUTTON_DANGER_BACKGROUND: #e33d51;
$BUTTON_DANGER_BACKGROUND_HOVER: #e54a5d;
$BUTTON_DANGER_BACKGROUND_SELECTED: #b63141;
$BUTTON_DANGER_BOX_SHADOW: #f4b0b8;
$BUTTON_DANGER_BOX_SHADOW_2: rgba(136, 37, 49, 0.1);
$BUTTON_DANGER_BOX_SHADOW_HOVER: #f7c8cd;
$BUTTON_DANGER_BOX_SHADOW_HOVER_2: rgba(136, 37, 49, 0.3);
$BUTTON_DANGER_BOX_SHADOW_SELECTED: rgba(45, 45, 45, 0.4);

$BUTTON_INSET_COLOR: #8e8e8e;
$BUTTON_INSET_COLOR_HOVER: #0a0a0a;
$BUTTON_INSET_COLOR_SELECTED: #0a0a0a;
$BUTTON_INSET_TEXT_SHADOW: #ffffff;
$BUTTON_INSET_TEXT_SHADOW_SELECTED: #f5f5f5;
$BUTTON_INSET_BORDER: #cacaca;
$BUTTON_INSET_BORDER_HOVER: #a9a9a9;
$BUTTON_INSET_BORDER_SELECTED: #a9a9a9;
$BUTTON_INSET_BACKGROUND: transparent;
$BUTTON_INSET_BACKGROUND_HOVER: #ffffff;
$BUTTON_INSET_BACKGROUND_SELECTED: #ffffff;
$BUTTON_INSET_BOX_SHADOW: #ffffff;
$BUTTON_INSET_BOX_SHADOW_2: #ffffff;
$BUTTON_INSET_BOX_SHADOW_HOVER: #ffffff;
$BUTTON_INSET_BOX_SHADOW_HOVER_2: rgba(147, 147, 147, 0.4);
$BUTTON_INSET_BOX_SHADOW_SELECTED: rgba(143, 143, 143, 0.4);
$BUTTON_INSET_BOX_SHADOW_SELECTED_2: #ffffff;

$BUTTON_INSET_DARK_COLOR: #d2d2d2;
$BUTTON_INSET_DARK_COLOR_HOVER: #d2d2d2;
$BUTTON_INSET_DARK_COLOR_SELECTED: #d2d2d2;
$BUTTON_INSET_DARK_TEXT_SHADOW: #000000;
$BUTTON_INSET_DARK_TEXT_SHADOW_SELECTED: #000000;
$BUTTON_INSET_DARK_BORDER: #2d2d2d;
$BUTTON_INSET_DARK_BORDER_HOVER: #000000;
$BUTTON_INSET_DARK_BORDER_SELECTED: #000000;
$BUTTON_INSET_DARK_BACKGROUND: transparent;
$BUTTON_INSET_DARK_BACKGROUND_HOVER: #535353;
$BUTTON_INSET_DARK_BACKGROUND_SELECTED: rgba(0, 0, 0, 0.2);
$BUTTON_INSET_DARK_BOX_SHADOW: #606060;
$BUTTON_INSET_DARK_BOX_SHADOW_2: #606060;
$BUTTON_INSET_DARK_BOX_SHADOW_HOVER: #606060;
$BUTTON_INSET_DARK_BOX_SHADOW_HOVER_2: rgba(27, 27, 27, 0.4);
$BUTTON_INSET_DARK_BOX_SHADOW_SELECTED: rgba(0, 0, 0, 0.4);
$BUTTON_INSET_DARK_BOX_SHADOW_SELECTED_2: #606060;

$BUTTON_SECRET_COLOR: #8e8e8e;
$BUTTON_SECRET_COLOR_HOVER: #0a0a0a;
$BUTTON_SECRET_COLOR_SELECTED: #0a0a0a;
$BUTTON_SECRET_TEXT_SHADOW: #ffffff;
$BUTTON_SECRET_TEXT_SHADOW_SELECTED: #f5f5f5;
$BUTTON_SECRET_BORDER: transparent;
$BUTTON_SECRET_BORDER_HOVER: #a9a9a9;
$BUTTON_SECRET_BORDER_SELECTED: #a9a9a9;
$BUTTON_SECRET_BACKGROUND: transparent;
$BUTTON_SECRET_BACKGROUND_HOVER: #ffffff;
$BUTTON_SECRET_BACKGROUND_SELECTED: rgba(143, 143, 143, 0.2);
$BUTTON_SECRET_BOX_SHADOW_HOVER: #ffffff;
$BUTTON_SECRET_BOX_SHADOW_HOVER_2: rgba(147, 147, 147, 0.4);
$BUTTON_SECRET_BOX_SHADOW_SELECTED: rgba(143, 143, 143, 0.4);
$BUTTON_SECRET_BOX_SHADOW_SELECTED_2: #ffffff;
