$border-color: #cbcbcb;
$bg-color: #fff;
$bg-color-header: #f5f5f5;

.list-container {
    border: 1px solid $border-color;
    background-color: $bg-color;
    border-radius: 3px;
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }
}

.list-multiple-navigation {
    .list-container_header {
        border-top: 1px solid #cacaca;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .button__filter {
            display: none;
        }
        
        @include media-breakpoint-down(xs) {
            .button__filter {
                display: inline-block;
            }
        }
    }
    &:first-child .list-container_header {
        border-top: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
}

.list-container_header {
    background-color: $bg-color-header;
    border-bottom: 1px solid $border-color;
    padding: 5px 10px;
    text-align: justify;
    font-size: 0;
    &.container_header {
        padding: 10px;
    }
}

.list-container_header--title {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #646464;

    &:last-child:only-child {
        margin-bottom: 0;
    }
}

.list-container_header--item {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    line-height: 1;
    margin: 0;
}

.list-stack {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

.list-stack--item {
    list-style: none;
    &~.list-stack--item {
        border-top: 1px solid #eaeaea;
    }
}

ul.list-stack--nested {
    .list-stack--item_content {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.list-stack--item_content {
    position: relative;
    padding: 10px;
}

.list-link__nav {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;
    display: block;
    line-height: 1em;
    text-decoration: none;
    &.selected {
        color: #fff;
        box-shadow: 0 0 0 1px #006080;
        border-top-color: #338099;
        background-color: #338099;
        &:active,
        &:focus,
        &:hover {
            background-color: #338099;
        }
        .list-count {
            color: #fff;
        }
    }
}

.list-link__nav:active,
.list-link__nav:focus,
.list-link__nav:hover {
    background-color: #f5f5f5;
    text-decoration: none;
}

.list-stack--affix {
    position: absolute;
    right: 10px;
}
.list-count {
    color: #707070;
    font-size: 12px;
}

.list-stack--item__nesting {
    .list-stack:last-child {
        border-radius: 0;
        border-bottom: none;
    }
    .list-stack {
        box-shadow: inset 0 1px 3px 0 rgba(45,45,45,.1);
        background-color: #f9f9f9;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
    }
    [data-toggle=collapse] {
        position: relative;
        padding-left: 20px;
        border-top: 1px solid $border-color;
        &:before {
            content: "\25BA";
            font-size: 0.6em;
            position: absolute;
            left: 6px;
            color: #707070;
        }
        +.list-stack {
            display: none;
        }
        &.open {
            &:before {
                content: "\25BC";
            }
            +.list-stack {
                display: block;
            }
        }
    }
}
