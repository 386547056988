
@import '../grid/bootstrap-grid';

#footer-universal {
    padding-top: 40px;
    padding-bottom: 60px;
}

.layout-content {
    &--lined {
        margin-bottom: 24px;
        padding-top: 24px;
        border-top: 1px solid #eaeaea;
    }
    &__section {
        margin-bottom: 24px;
        border-top: 1px solid #eaeaea;
        clear: both;
        padding-top: 24px;
        margin-top: 24px;

        &:first-child {
            border-top-style: hidden;
            margin-top: 0;
            padding-top: 0;
        }

        @include media-breakpoint-down(xm) {
            &.home-content {
                margin-left: 12px;
                margin-right: 12px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .layout-content__column {
        margin-bottom: 24px;
    }
}
@include media-breakpoint-down(md) {
    .layout-content__column .toolbar {
        .toolbar--item {
            display: block;
        }
        .toolbar--item~.toolbar--item {
            margin-top: 14px;
            margin-left: 0;
        }
    }
}
