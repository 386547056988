.dropdown__small {
    background: white;
    font-size: 12px;
    height: 28px;
    line-height: 1.6em;
    padding-right: 9px;
    width: 100%;

    &:after {
        background: {
            image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPjx0aXRsZT5kcm9wZG93bjwvdGl0bGU+PHBhdGggZD0iTTE2IDNsLTYgOWgxMmwtNi05ek0xNiAyOWw2LTloLTEybDYgOXoiPjwvcGF0aD48L3N2Zz4=);
            size: contain;
            repeat: no-repeat;
            position: 6px 4px;
        }
        border-left: 1px solid rgba(203, 203, 203, 0.5);
        content: "";
        display: block;
        height: 26px;
        float: right;
        margin: {
            top: -4px;
            right: 0;
        }
        padding: {
            top: 0;
            right: 8px;
            bottom: 0;
        }
        opacity: 0.6;
        transform: scale(0.8);
        vertical-align: top;
        width: 10px;
    }
    .js-dropdown-placeholder {
        padding: 4px 8px;
    }
}

.secondary_nav--placeholder {
    background: none;
    padding: {
        top: 0;
        right: 10px;
        bottom: 0;
        left: 6px;
    }
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    z-index: 1;
    zoom: 1;
    vertical-align: middle;
    white-space: nowrap;
}

.select-input-container {
    display: inline-block;
    .dropdown__small:after {
        display: none;
    }
}

.dropdown {
    position: relative;
    border-radius: 3px;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    width: auto;
    font-weight: 400;
    text-shadow: 0 1px 0 #fefefe;
    text-decoration: none;
    border: 1px solid #c4c4c4;
    background: #f5f5f5;
    color: #585858;
    box-shadow: inset 0 1px 0 0 #fff, 0 2px 2px 0 rgba(147,147,147,.1);
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    padding-right: 36px;

    &:before {
        border-left: 1px solid rgba(203,203,203,.5);
        margin-right: 0;
        text-align: center;
        border-radius: 0 3px 3px 0;
        position: absolute;
        top: 0;
        right: 0;
    }

    &:hover {
        text-shadow: 0 1px 0 #fefefe;
        text-decoration: none;
        border-color: #a7a7a7;
        background-color: #fdfdfd;
        box-shadow: inset 0 1px 0 0 #fff, 0 2px 3px 0 rgba(147,147,147,.3);
    }
}

.dropdown__small:before {
    padding: 4px 0;
    width: 28px;
}

.js-dropdown-placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    z-index: 1;
    padding: 6px 12px;
    background: 0 0;
}

.js-dropdown-select {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%!important;
    height: 100%;
    border: 0;
    background: 0 0;
}
