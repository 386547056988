.tertiary-nav--items {
    margin: 5px 15px;
    padding-left: 0;
    font-size: 0;
    color: var(--t-tab-inactive, inherit);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: sticky;
    top: 125px;

    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.tertiary-nav--item {
    list-style: none;
    display: inline-block;
    flex-basis: 100%;

    &.is-selected .tertiary-nav--link {
        color: var(--t-tab-active, inherit);

        &:before {
            width: 3px;
            height: calc(100% - 20px);
            top: 10px;
        }
    }

    .card__caption {
        font-size: 11px;
        color: var(--t-tab-inactive, inherit);
        text-align: right;
        margin-top: -6px;
        margin-bottom: 6px;
        line-height: 14px;
        a {
            color: var(--t-tab-inactive, inherit);
        }
    }
    .button__profile {
        margin-left: 6px;
        margin-top: 6px;
    }
}

.tertiary-nav--link {
    color: var(--t-tab-inactive, inherit);
    background-color: transparent;
    transition-property: color;
    transition-duration: .1s;
    transition-timing-function: linear;
    position: relative;
    &:hover {
        color: var(--t-tab-active, inherit);
        text-decoration: none;
    }
}

.tertiary-nav--content, .tertiary-nav--link {
    display: block;
    font-size: 14px;
    padding: 10px;
}

.tertiary-nav__menu {
    padding: 12px 0;
    &__label {
        font-size: 14px;
    }
}

.tertiary_nav--item__right {
    margin-right: 0;
}

.tertiary-nav--button {
    text-align: right;
    padding: 12px 0;
    margin-top: -6px;
}

.tertiary-nav--link:before {
    position: absolute;
    height: 0;
    width: 3px;
    content: '';
    bottom: -1px;
    display: block;
    left: 0;
    background-color: currentColor;
    transition-property: height;
    transition-duration: .1s;
    transition-timing-function: ease-in;
}

