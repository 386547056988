.hero {
    width: 100%;
    position: relative;
    overflow: hidden;

    &__container {
        border: 1px solid #eeeeee;
        overflow: hidden;
        height: 300px;
        position: relative;
    
        @include media-breakpoint-down(xm) {
            border: 0;
        }
    }

    &__carousel__container {
        z-index: 1;
        position: relative;
        left: 50%;
        margin-left: -288px;
        max-width: 100%;
    
        @include media-breakpoint-down(md) {
            margin-left: -50%;
        }
    }

    &__item {
        transition: all .2s ease-in;
        width: 576px;
        float: left;
        position: relative;
        left: 0;
        right: 0;
    
        &.prev-selected,
        &.next-selected {
            background: #fff;
            .hero__item_card:before {
                opacity: 0;
            }
        }
    }

    &__item_card {
        position: relative;
        margin: 0 12px;
        border: 1px solid #cbcbcb;
        transition: all .2s ease-in;
        border-radius: 3px;
        height: 300px;
        padding: 24px;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
    
        @include media-breakpoint-down(xm) {
            flex-direction: column;
            margin: 0;
            border: 0;
            padding: 0;
        }
    
        &:before {
            transition: all .2s ease-in;
            border-radius: 3px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: block;
            content: '';
            background-color: #f5f5f5;
            opacity: .6;
            z-index: 3;
        }
    }

    &__item_content,
    &__item_image {
        width: 50%;
        padding-right: 24px;

        @include media-breakpoint-down(xm) {
            padding-right: 0;
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
            
            .title__xxlarge {
                font: bold 20px/1.4 "Helvetica Neue",Helvetica,Arial,sans-serif;
            }
            p {
                margin: 0.5em 0;
            }
            .button__large {
                font-size: 12px;
                height: 28px;
                padding: 4px 8px;
            }
            img {
                max-height: 180px;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        &__item_image {
            margin-bottom: 10px;
        }
    }

    &__carousel-nav {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;

        &__prev, &__next {
            list-style: none;

            &__link {
                transition: all .2s ease-in;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 120px;
                display: block;
                z-index: 3;
    
                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    top: 10%;
                    height: 80%;
                    width: 100%;
                    left: -100%;
                    margin-left: -1px;
                    @include media-breakpoint-down(xs) {
                        box-shadow: none;
                    }
                }
            }
        }

        &__next {
            &__link {
                right: 0;

                &:before {
                    left: 100%;
                    margin-left: 0;
                }
            }
        }
    }
}


@include media-breakpoint-down(xm) {
    .hero__carousel-nav__next__link, .hero__carousel-nav__prev__link {
        display:none !important;
    }
}


.hero__item.slick-active .hero__item_card:before {
    opacity: 0;
    display: none;
}
