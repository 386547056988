body {
    margin: 0;
}

.text-center {
    text-align: center;
}

ul {
    list-style: none;
}
/// Mixins, Functions, Helpers
@import 'functions/_manifest';
@import 'mixins/_manifest';

/// Setings
@import 'settings/_manifest';

// Reset
// @import 'layout/reset';
// Boostrap Layout

// UIF Layout
@import 'layout/_manifest';

/// Components
@import 'components/_manifest';

/// pages
@import 'pages/profile';
@import 'pages/listing';
@import 'pages/compare';
@import 'pages/browse';
