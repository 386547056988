/// Maps

//
/// Given a list of key-value pairs, gets the last key-value pair.
/// @parameter {List} $list-map  a list of 2-item lists i.e. key-value pairs, e.g. foo 2, bar 3
/// @return {object} last key-value pair in list.

@function last-value($list) {
    @return nth($list, length($list));
}
