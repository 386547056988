@import 'button-colors';

.button {
    display: inline-block;
    text-transform: none;
    font-size: 14px;
    padding: 10px;
    font-weight: 600;
    width: auto;
    line-height: 1.4285714286;
    box-shadow: $BUTTON_BOX_SHADOW;
    color: $BUTTON_COLOR;
    text-shadow: 0 1px 0 $BUTTON_TEXT_SHADOW;
    border: 1px solid $BUTTON_BORDER;
    background: $BUTTON_BACKGROUND;
    transition:
        background-color var(--tct-btn-tween, 0.2s ease),
        color var(--tct-btn-tween, 0.2s ease),
        box-shadow var(--tct-btn-tween, 0.2s ease),
        fill var(--tct-btn-tween, 0.2s ease),
        border-color var(--tct-btn-tween, 0.2s ease),
        border-width var(--tct-btn-tween, 0.2s ease);
    &.button__square {
        padding: 6px 0;
        width: 36px;
    }

    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    box-sizing: border-box;
    border-radius: 3px;
    user-select: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;

    &:disabled, &.is-disabled {
        opacity: 0.5;
    }

    &:disabled:hover, &.is-disabled:hover {
        cursor: not-allowed;
    }

    &:disabled:active, &.is-disabled:active,
    &:disabled:focus, &.is-disabled:focus,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        text-shadow: none;
    }

    &:disabled, &.is-disabled {
        opacity: 0.4;
        box-shadow: none;
    }

    &:hover,
    &:focus {
        color: $BUTTON_COLOR_SELECTED;
        text-shadow: 0 1px 0 $BUTTON_TEXT_SHADOW;
        border-color: $BUTTON_BORDER_HOVER;
        background-color: $BUTTON_BACKGROUND_HOVER;
        box-shadow: $BUTTON_BOX_SHADOW_HOVER;
    }

    &:active,
    &.is-active {
        color: $BUTTON_COLOR_SELECTED;
        text-shadow: 0 1px 0 $BUTTON_TEXT_SHADOW_SELECTED;
        border-color: $BUTTON_BORDER_SELECTED;
        background-color: $BUTTON_BACKGROUND_SELECTED;
        box-shadow: $BUTTON_BOX_SHADOW_SELECTED;
    }

    &:disabled:hover, &.is-disabled:hover,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        color: $BUTTON_COLOR;
        text-shadow: 0 1px 0 $BUTTON_TEXT_SHADOW;
        border-color: $BUTTON_BORDER;
        background-color: $BUTTON_BACKGROUND;
        box-shadow: none;
    }

    &__primary {
        &,
        &:disabled, &.is-disabled{
            color: $BUTTON_PRIMARY_COLOR;
            text-shadow: 0 -1px 0 $BUTTON_PRIMARY_TEXT_SHADOW;
            border: 1px solid $BUTTON_PRIMARY_BORDER;
            background: $BUTTON_PRIMARY_BACKGROUND;
            box-shadow: $BUTTON_PRIMARY_BOX_SHADOW;
        }

        &:hover,
        &:focus {
            color: $BUTTON_PRIMARY_COLOR;
            text-shadow: 0 -1px 0 $BUTTON_PRIMARY_TEXT_SHADOW;
            border-color: $BUTTON_PRIMARY_BORDER_HOVER;
            background-color: $BUTTON_PRIMARY_BACKGROUND_HOVER;
            box-shadow: $BUTTON_PRIMARY_BOX_SHADOW_HOVER;
        }

        &:active,
        &.is-active {
            color: $BUTTON_PRIMARY_COLOR_SELECTED;
            text-shadow: 0 -1px 0 $BUTTON_PRIMARY_TEXT_SHADOW_SELECTED;
            border-color: $BUTTON_PRIMARY_BORDER_SELECTED;
            background-color: $BUTTON_PRIMARY_BACKGROUND_SELECTED;
            box-shadow: $BUTTON_PRIMARY_BOX_SHADOW_SELECTED;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover:hover {
            color: $BUTTON_PRIMARY_COLOR;
            text-shadow: 0 1px 0 $BUTTON_PRIMARY_TEXT_SHADOW;
            border-color: $BUTTON_PRIMARY_BORDER;
            background-color: $BUTTON_PRIMARY_BACKGROUND;
            box-shadow: none;
        }
    }

    &__secondary {
        &,
        &:disabled, &.is-disabled {
            color: $BUTTON_SECONDARY_COLOR;
            text-shadow: 0 -1px 0 $BUTTON_SECONDARY_TEXT_SHADOW;
            border: 1px solid $BUTTON_SECONDARY_BORDER;
            background: $BUTTON_SECONDARY_BACKGROUND;
            box-shadow: $BUTTON_SECONDARY_BOX_SHADOW;
        }

        &:hover,
        &:focus {
            color: $BUTTON_SECONDARY_COLOR;
            text-shadow: 0 -1px 0 $BUTTON_SECONDARY_TEXT_SHADOW;
            border-color: $BUTTON_SECONDARY_BORDER_HOVER;
            background-color: $BUTTON_SECONDARY_BACKGROUND_HOVER;
            box-shadow: $BUTTON_SECONDARY_BOX_SHADOW_HOVER;
        }

        &:active,
        &.is-active {
            color: $BUTTON_SECONDARY_COLOR_SELECTED;
            text-shadow: 0 -1px 0 $BUTTON_SECONDARY_TEXT_SHADOW_SELECTED;
            border-color: $BUTTON_SECONDARY_BORDER_SELECTED;
            background-color: $BUTTON_SECONDARY_BACKGROUND_SELECTED;
            box-shadow: $BUTTON_SECONDARY_BOX_SHADOW_SELECTED;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover:hover {
            color: $BUTTON_SECONDARY_COLOR;
            text-shadow: 0 1px 0 $BUTTON_SECONDARY_TEXT_SHADOW;
            border-color: $BUTTON_SECONDARY_BORDER;
            background-color: $BUTTON_SECONDARY_BACKGROUND;
            box-shadow: none;
        }
    }

    &.button__emphasis {
        &,
        &:disabled, &.is-disabled {
            color: $BUTTON_EMPHASIS_COLOR;
            text-shadow: 0 -1px 0 $BUTTON_EMPHASIS_TEXT_SHADOW;
            border: 1px solid $BUTTON_EMPHASIS_BORDER;
            background: $BUTTON_EMPHASIS_BACKGROUND;
            box-shadow: $BUTTON_EMPHASIS_BOX_SHADOW;
        }

        &:hover,
        &:focus {
            color: $BUTTON_EMPHASIS_COLOR;
            text-shadow: 0 -1px 0 $BUTTON_EMPHASIS_TEXT_SHADOW;
            border-color: $BUTTON_EMPHASIS_BORDER_HOVER;
            background-color: $BUTTON_EMPHASIS_BACKGROUND_HOVER;
            box-shadow: $BUTTON_EMPHASIS_BOX_SHADOW_HOVER;
        }

        &:active,
        &.is-active {
            color: $BUTTON_EMPHASIS_COLOR_SELECTED;
            text-shadow: 0 -1px 0 $BUTTON_EMPHASIS_TEXT_SHADOW_SELECTED;
            border-color: $BUTTON_EMPHASIS_BORDER_SELECTED;
            background-color: $BUTTON_EMPHASIS_BACKGROUND_SELECTED;
            box-shadow: $BUTTON_EMPHASIS_BOX_SHADOW_SELECTED;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover:hover {
            color: $BUTTON_EMPHASIS_COLOR;
            text-shadow: 0 1px 0 $BUTTON_EMPHASIS_TEXT_SHADOW;
            border-color: $BUTTON_EMPHASIS_BORDER;
            background-color: $BUTTON_EMPHASIS_BACKGROUND;
            box-shadow: none;
        }
    }

    &__secret {
        &,
        &:disabled, &.is-disabled {
            color: $BUTTON_SECRET_COLOR;
            text-shadow: 0 1px 0 $BUTTON_SECRET_TEXT_SHADOW;
            border: 1px solid $BUTTON_SECRET_BORDER;
            background: $BUTTON_SECRET_BACKGROUND;
            box-shadow: none;
        }

        &:hover,
        &:focus {
            color: $BUTTON_SECRET_COLOR_HOVER;
            border-color: $BUTTON_SECRET_BORDER_HOVER;
            background-color: $BUTTON_SECRET_BACKGROUND_HOVER;
            box-shadow:$BUTTON_SECRET_BOX_SHADOW_HOVER, 0 1px 3px -1px $BUTTON_SECRET_BOX_SHADOW_HOVER_2;
        }

        &:active,
        &.is-active {
            color: $BUTTON_SECRET_COLOR_SELECTED;
            text-shadow: 0 1px 0 $BUTTON_SECRET_TEXT_SHADOW_SELECTED;
            border-color: $BUTTON_SECRET_BORDER_SELECTED;
            background: $BUTTON_SECRET_BACKGROUND_SELECTED;
            box-shadow: inset 0 1px 3px 0 $BUTTON_SECRET_BOX_SHADOW_SELECTED, 0 1px 0 0 $BUTTON_SECRET_BOX_SHADOW_SELECTED_2;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover:hover {
            color: $BUTTON_SECRET_COLOR;
            text-shadow: 0 1px 0 $BUTTON_SECRET_TEXT_SHADOW;
            border-color: $BUTTON_SECRET_BORDER;
            background-color: $BUTTON_SECRET_BACKGROUND;
            box-shadow: none;
        }
    }

    &__danger {
        &,
        &:disabled, &.is-disabled {
            color: $BUTTON_DANGER_COLOR;
            text-shadow: 0 -1px 0 $BUTTON_DANGER_TEXT_SHADOW;
            border: 1px solid $BUTTON_DANGER_BORDER;
            background: $BUTTON_DANGER_BACKGROUND;
            box-shadow: inset 0 1px 0 0 $BUTTON_DANGER_BOX_SHADOW, 0 2px 2px 0 $BUTTON_DANGER_BOX_SHADOW_2;
        }

        &:hover,
        &:focus {
            color: $BUTTON_DANGER_COLOR;
            text-shadow: 0 -1px 0 $BUTTON_DANGER_TEXT_SHADOW;
            border-color: $BUTTON_DANGER_BORDER_HOVER;
            background-color: $BUTTON_DANGER_BACKGROUND_HOVER;
            box-shadow: inset 0 1px 0 0 $BUTTON_DANGER_BOX_SHADOW_HOVER, 0 2px 3px 0 $BUTTON_DANGER_BOX_SHADOW_HOVER_2;
        }

        &:active,
        &.is-active {
            color: $BUTTON_DANGER_COLOR_SELECTED;
            text-shadow: 0 1px 0 $BUTTON_DANGER_TEXT_SHADOW_SELECTED;
            border-color: $BUTTON_DANGER_BORDER_SELECTED;
            background-color: $BUTTON_DANGER_BACKGROUND_SELECTED;
            box-shadow: inset 0 2px 6px -1px $BUTTON_DANGER_BOX_SHADOW_SELECTED;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover:hover {
            color: $BUTTON_DANGER_COLOR;
            text-shadow: 0 1px 0 $BUTTON_DANGER_TEXT_SHADOW;
            border-color: $BUTTON_DANGER_BORDER;
            background-color: $BUTTON_DANGER_BACKGROUND;
            box-shadow: none;
        }
    }

    &__error {
        &,
        &:disabled, &.is-disabled {
            border-color: #f0949f;
        }

        &:hover,
        &:focus {
            border-color: #f0949f;
        }

        &:active,
        &.is-active {
            border-color: #f0949f;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover:hover {
            border-color: #f0949f;
            box-shadow: none;
        }
    }

    &__inset {
        &,
        &:disabled, &.is-disabled {
            color: $BUTTON_INSET_COLOR;
            text-shadow: 0 1px 0 $BUTTON_INSET_TEXT_SHADOW;
            border: 1px solid $BUTTON_INSET_BORDER;
            background: $BUTTON_INSET_BACKGROUND;
            box-shadow: inset 0 1px 0 0 $BUTTON_INSET_BOX_SHADOW, 0 1px 0 0 $BUTTON_INSET_BOX_SHADOW_2;
        }

        &:hover,
        &:focus {
            color: $BUTTON_INSET_COLOR_HOVER;
            border-color: $BUTTON_INSET_BORDER_HOVER;
            background: $BUTTON_INSET_BACKGROUND_HOVER;
            box-shadow: inset 0 1px 0 0 $BUTTON_INSET_BOX_SHADOW_HOVER, 0 1px 3px -1px $BUTTON_INSET_BOX_SHADOW_HOVER_2;
        }

        &:active,
        &.is-active {
            color: $BUTTON_INSET_COLOR_SELECTED;
            text-shadow: 0 1px 0 $BUTTON_INSET_TEXT_SHADOW_SELECTED;
            border-color: $BUTTON_INSET_BORDER_SELECTED;
            background: $BUTTON_INSET_BACKGROUND_SELECTED;
            box-shadow: inset 0 1px 3px 0 $BUTTON_INSET_BOX_SHADOW_SELECTED, 0 1px 0 0 $BUTTON_INSET_BOX_SHADOW_SELECTED_2;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover {
            color: $BUTTON_INSET_COLOR;
            text-shadow: 0 1px 0 $BUTTON_INSET_TEXT_SHADOW;
            border-color: $BUTTON_INSET_BORDER;
            background-color: $BUTTON_INSET_BACKGROUND;
            box-shadow: none;
        }
    }

    &__inset-dark {
        &,
        &:disabled, &.is-disabled {
            color: $BUTTON_INSET_DARK_COLOR;
            text-shadow: 0 -1px 0 $BUTTON_INSET_DARK_TEXT_SHADOW;
            border: 1px solid $BUTTON_INSET_DARK_BORDER;
            background: $BUTTON_INSET_DARK_BACKGROUND;
            box-shadow: inset 0 1px 0 0 $BUTTON_INSET_DARK_BOX_SHADOW, 0 1px 0 0 $BUTTON_INSET_DARK_BOX_SHADOW_2;
        }

        &:hover,
        &:focus {
            color: $BUTTON_INSET_DARK_COLOR_HOVER;
            text-shadow: 0 -1px 0 $BUTTON_INSET_DARK_TEXT_SHADOW;
            border-color: $BUTTON_INSET_DARK_BORDER_HOVER;
            background: $BUTTON_INSET_DARK_BACKGROUND_HOVER;
            box-shadow: inset 0 1px 0 0 $BUTTON_INSET_DARK_BOX_SHADOW_HOVER, 0 1px 3px -1px $BUTTON_INSET_DARK_BOX_SHADOW_HOVER_2;
        }

        &:active,
        &.is-active {
            color: $BUTTON_INSET_DARK_COLOR_SELECTED;
            text-shadow: 0 -1px 0 $BUTTON_INSET_DARK_TEXT_SHADOW_SELECTED;
            border-color: $BUTTON_INSET_DARK_BORDER_SELECTED;
            background: $BUTTON_INSET_DARK_BACKGROUND_SELECTED;
            box-shadow: inset 0 1px 3px 0 $BUTTON_INSET_DARK_BOX_SHADOW_SELECTED, 0 1px 0 0 $BUTTON_INSET_DARK_BOX_SHADOW_SELECTED_2;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover:hover {
            color: $BUTTON_INSET_DARK_COLOR;
            text-shadow: 0 1px 0 $BUTTON_INSET_DARK_TEXT_SHADOW;
            border-color: $BUTTON_INSET_DARK_BORDER;
            background-color: $BUTTON_INSET_DARK_BACKGROUND;
            box-shadow: none;
        }
    }

    &__small {
        &.button__square {
            padding: 4px 0;
            width: 28px;
        }
    }

    &__large {
        &.button__square {
            padding: 8px 0;
            width: 42px;
        }
    }

    &__xlarge {
        &.button__square {
            padding: 10px 0;
            width: 48px;
        }
    }

    &__full_width {
        display: block;
    }
}

.toolbar {
    & .toolbar--item {
        display: inline-block;
        vertical-align: middle;
    }
    & .toolbar--item~.toolbar--item {
        margin-left: 10px;
    }
    &__small .toolbar--item~.toolbar--item {
        margin-left: 10px;
    }
}

.toggle_buttons {
    .button {
        border-radius: 0;
        margin-left: -1px;
        &:first-child {
            border-radius: 3px 0 0 3px;
        }
        &:last-child {
            border-radius: 0 3px 3px 0;
        }
    }
}

.selector__button {
    display: inline-block;
    text-align: left;
}

.button__secret {
    box-shadow: inset 0 1px 0 0 #fff, 0 2px 2px 0 rgba(147,147,147,.1);
    color: #585858;
    font-weight: 400;
    background: 0 0;
    color: #5f5f5f;
    border: 1px solid #c4c4c4;
    text-shadow: 0 1px 0 #fefefe;
    text-decoration: none;
    box-shadow: none;
    border-color: transparent;
}

.slick-dots {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    li {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin: 5px;
        padding: 0;
        cursor: pointer;

        &.slick-active button {
            background: #000;
            opacity: .75;
        }
        button {
            border: 0;
            background: #000;
            display: block;
            height: 10px;
            width: 10px;
            border-radius: 5px;
            padding: 5px;
            outline: 0;
            font-size: 0;
            color: transparent;
            cursor: pointer;
            opacity: .25;
        }
    }
}
