$modal-header-bg: transparent;
$modal-header-box-shadow: none;
$modal-header-border-bottom: none;
$modal-header-border-top: none;
$modal-footer-bg: #ffffff;
$modal-footer-box-shadow: none;
$modal-footer-border-top: none;
$blocker-bg: rgba(0, 0, 0, .4);

.modal {
    display: none;
    border-radius: 3px;
    z-index: 10001;
    padding: 0;
    box-shadow: none;
    .slick-dots {
        text-align: center;
        display: block;
        width: auto;
        display: flex;
        flex-wrap: wrap;
        min-height: 40px;
        align-content: center;
        justify-content: center;
    }
    .close {
        transition: opacity .1s linear;
        opacity: .5;
        color: inherit;
        display: block;
        position: absolute;
        top: 6px;
        font-size: 21px;
        right: 16px;
        &:hover {
            text-decoration: none;
            opacity: 1;
        }
    }
}

.modal--header {
    text-align: center;
    margin: 0;
    border-bottom: $modal-header-border-bottom;
    background: $modal-header-bg;
    box-shadow: $modal-header-box-shadow;
    border-top: $modal-header-border-top;
    box-sizing: border-box;
}

.modal--content {
    padding: 14px;
    overflow: auto;
    min-height: 134.4px;
    max-height: 60vh;
}

.modal--footer {
    background: $modal-footer-bg;
    box-shadow: $modal-footer-box-shadow;
    border-top: $modal-footer-border-top;
    padding: 10px;
    border-radius: 0 0 4px 4px;
    margin: 0;
    box-sizing: border-box;
    min-height: 58px;
}

.modal-footer-right {
    float: right;
}
.modal-footer-left {
    float: left;
}

.slider--image {
    height: 100%;
    min-height: 1px;
    width: 524px;
    float: left;
    text-align: center;
    img {
        max-width: 100%;
        max-height: 500px;
    }
}

.toolbar__polar {
    position: relative;
    text-align: justify;
    font-size: 0;
    line-height: 0;
    text-justify: distribute-all-lines;
}

.blocker {
    z-index: 10000;
    transition: opacity .1s linear;
    background: $blocker-bg;
}

.close-modal {
    display: none !important;
}

