.addon-products-checklist {
    padding-left: 24px;
    &--flush {
        padding-left: 0;
    }
    &__item {
        position: relative;
        margin-left: 25px;
        margin-top: 10px;
        &:before {
            font-family: AppDirectIcons;
            content: "\e631";
            font-size: 10px;
            position: absolute;
            left: -25px;
            text-align: center;
            width: 19px;
        }
        &.badges, &.profile__edition-fees, &:first-child {
            margin-left: 0;
            &:before {
                font-family: AppDirectIcons;
                text-align: center;
                display: inline-block;
                width: 24px;
                content: " ";
                position: absolute;
                left: -24px;
            }
        }
    }
}

.addon-products {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid #cbcbcb;
    background-color: #fff;
    border-radius: 3px;
    &__header {
        background-color: #f5f5f5;
        padding: 10px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: 1px solid #cbcbcb;
        & h3 {
            text-align: center;
            margin-bottom: 0;
        }
    }
    &__content {
        padding: 14px;
    }
    &__prices {
        margin-bottom: 14px;
        &-separator {
            margin-left: 0;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            z-index: 1;
            & span {
                font-size: 10px;
                display: inline-block;
                padding: 0 8px;
                background-color: #fff;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #eaeaea;
            }
        }
    }
    &__price {
        .edition__price-amount {
            font: bold 14px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: 400;
        }
        &:first-child .edition__price--primary-amount {
            font: bold 32px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
        & .edition__price-frequency, & .edition__price-unit {
            text-transform: lowercase;
        }
    }
    &__price--primary {
        margin-top: 0;
        margin-left: 0;
        .edition__price--primary-amount {
            font-size: 18px;
            font-weight: bold;
        }
        &:before {
            font-family: AppDirectIcons;
            text-align: center;
            display: inline-block;
            width: 24px;
            content: " ";
            position: absolute;
            left: -24px;
        }
    }
    &__action {
        text-align: center;
        margin-bottom: 14px;
    }
    &__details {
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 14px;
        margin-bottom: 14px;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
    &__bullets {
        li:nth-child(n+8) {
            display: none;
        }
        .toggle-show, .toggle-hide {
            display: inline-block;
        }
        .toggle-hide {
            display: none;
            margin-top: 6px;
        }
        &.toggled {
            li:nth-child(n+8) {
                display: list-item;
            }
            .toggle-show {
                display: none;
            }
            .toggle-hide {
                display: inline-block;
            }
        }
    }
}

.profile__edition-fees--container {
    background-color: #f5f5f5;
    margin: 0;
    padding: 14px;
}
