.listing-page {

    * {
        box-sizing: border-box;
    }

    &.listing-grid-list {
        .js-grid-view {
            @extend .button.is-active;
        }
        .listing-items-row { display: none; }
        .listing-items-grid { display: block; }
    }

    &.listing-show-list {
        .js-list-view {
            @extend .button.is-active;
        }
        .listing-items-row { display: block; }
        .listing-items-grid { display: none; }
    }

    .listing-row {
        display: -ms-flexbox;
        display: flex;
        &:not(:first-child) {
            border-top: 1px solid #eaeaea;
        }
    }

    .listing-row-content, .listing-row-image, .listing-row-toolbar {
        padding: 14px;
    }

    .listing-row-image {
        width: 148px;
        text-align: center;
        p {
            margin-bottom: 10px;
        }
    }

    .listing-row-content {
        word-wrap: break-word;
        padding-left: 0;
        padding-right: 0;
        -ms-flex: 1 0 1px;
        flex: 1 0 1px;
        min-width: 0;
    }

    .listing-row-badges {
        margin: 0 4px;
    }

    .listing-row-toolbar {
        text-align: right;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .listing-row-content-title {
        font: bold 18px/1.4em "Helvetica Neue",Helvetica,Arial,sans-serif;
        word-break: break-all;
        vertical-align: middle;
    }

    .listing-row-price {
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-bottom: 14px;
    }

    .listing-description {
        max-height: 90px;
        overflow: hidden;
    }

    .listing-row-actions{
        margin-left: auto;
    }

    .price--value {
        font: bold 32px/1.4 "Helvetica Neue",Helvetica,Arial,sans-serif;
        margin-right: 4px;
    }

    .listing-items {
        position: relative;
    }

    .listing-overlay {
        position: absolute;
        top: 0; 
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: #fff;
        opacity: .5;
    }


    .merchandising-badges {
        display: flex;
        align-items: center;
        margin-top: 6px;

        &:last-child {
            margin-right: -3px;
        }
        &:first-child {
            margin-left: -3px;
        }
    }

    .merchandising-badges-item {
        margin: 0 3px;
        img {
            width: 100%;
            height: auto;
        }
    }

    .filters--tags{
        text-align: left;
    }

    .tag {
        display: inline-block;
        margin-right: 6px;
        border-radius: 3px;
        background-clip: padding-box;
        font-size: 12px;
        line-height: 1;
        padding: 4px 8px;
        width: auto;
        color: inherit;
        margin-top: 6px;
        border: 1px solid #cbcbcb;
        background-color: #fff;
        white-space: nowrap;
    }

    .tag--text {
        display: inline-block;
        vertical-align: middle;
    }

    .tag--remove {
        position: static;
        margin-left: 4px;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        transition: opacity .1s linear;
        opacity: .5;
        color:rgb(87, 87, 87);
        &:hover{
            text-decoration: none;
            color: #e33d51;
            opacity: 1;
        }
        &:before {
            font-style:normal;
            font-family: AppDirectIcons;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            content: "\e020";
        }
    }
    .listing-items-row, .listing-items-grid { display: none; }


    .tile-listing {
        font-size: 12px;
        line-height: 1.6em;
        position: relative;
        height: 240px;
        border-top: 1px solid #eaeaea;
        border-left: 1px solid #eaeaea;
        text-align: left;
        cursor: pointer;
        width: 33.33333%;
        &:nth-child(3n+1) {
            border-left-style: hidden;
        }
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            border-top-style: hidden;
        }
        &:hover {
            background-color: #f5f5f5;
        }
        &.tile--empty {
            &:hover {
                background-color: transparent;
                cursor: auto;
            }
        }
        &.tile:nth-child(4n - 2) {
            padding-left: 14px;
        }
    }

    .tile--details {
        text-align: justify;
        font-size: 0;
        line-height: 0;
        text-justify: distribute-all-lines;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 14px;
        width: 100%;
    }

    .summary--title {
        margin-bottom: 0;
    }

    .tile--details_item {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }

    .tile__content--description {
        word-wrap: break-word;
        height: 60px;
        overflow: hidden;
        margin-bottom: 6px;
    }

    .card {
        margin: 14px 0;
        text-align: left;
        overflow: hidden;
        &:first-child {
            margin-top: 0;
        }
    }

    .listing-grid-price {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .listing-grid-price-container {
        -ms-flex: 1 0 1px;
        flex: 1 0 1px;
        .subtitle{
            margin-bottom: 0px;
        }
    }

    .listing-grid-price-compare {
        margin-left: 10px;
    }

    .selector {
        cursor: pointer;
    }

    .selector__small {
        line-height: 1.6em;
        font-size: 12px;
    }

    .ribbon-wrapper {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        height: 65px;
        width: 65px;
    }

    .button__filter-show, .button__filter-hide{
        display: none;
    }

    @include media-breakpoint-down(lg) {
        .listing-toggle-grid {
            display: none!important;
        }
    }
    @include media-breakpoint-down(md) {
        .listing {
            &-row-image {
                width: 90px;
                .id--placeholder {
                    font-size: .35em;
                }
            }
            .ribbon {
                line-height: 16px;
                right: -58px;
                font-size: 7px;
                top: 10px;
            }
            .tile-listing {
                .rating {
                    margin-top: 0px;
                }
            }
            .rating {
                margin-top: -11px;

                .rating__icon:before {
                    font-size:11px;
                }
            }
            
            .toolbar {
                margin-top: -11px;
                & .toolbar--item~.toolbar--item {
                    @include media-breakpoint-down(md) {
                        margin-top: 14px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .listing-navigator {
            display: block!important;
        }
    }

    @include media-breakpoint-down(xs) {
        .listing {
            &-page {
                position: relative;
            }
            &-navigator {
                display: none;
            }
            &-filters {
                .adb-selector {
                    padding: 3px 0;
                }
            }
        }
        .filters--filter {
            vertical-align: top;
        }
        .compare-label {
            .tooltip__text {
                display: none;
            }
        }
        .subtitle {
            display: none;
        }
        .pagination {
            margin-top: 5px;
            text-align: center;
            .pagination--button {
                padding: 6px 10px;
            }
        }
        .button__filter-show, .button__filter-hide {
            display: inline-block;
        }
        .button__filter-clear {
            display: none;
        }
    }

    @include media-breakpoint-down(xs) {
        .listing {
            &-row {
                flex-wrap: wrap;
                .button__primary {
                    display: none;
                }
                &-content {
                    width: calc(100% - 100px);
                    flex: none;
                }
                &-toolbar {
                    width: 100%;
                    text-align: left;
                    flex-direction: unset;
                    padding-top: 0px;
                    margin-top: -21px;
                    margin-left: 75px;
                }
                &-price {
                    margin-top: 6px;
                    margin-bottom: 6px;
                }
            }
        }
        .price--value {
            font: bold 14px/1.4 "Helvetica Neue",Helvetica,Arial,sans-serif;
            margin-right: 0px;
        }
        .js-listing-item-price, .price--value, .price--unit {
            font-size: 13px;
            display: inline;
        }
        .caption {
            display: none;
        }
    }

    @include media-breakpoint-down(xm) {
        h3 {
            font-size: 16px;
            line-height: 1.6;
        }
        .pagination {
            .pagination--button {
                padding: 6px 7px;
            }
        }
    }
}