[data-tecton-module] {
    @import 'alerts';
   @import 'badges';
   @import 'box';
   @import 'bundle-elements';
   @import 'button';
   @import 'card';
   @import 'compare';
   @import 'content-box';
   @import 'context-menu';
   @import 'description-list';
   @import 'dropdowns';
   @import 'form';
   @import 'header';
   @import 'hero';
   @import 'list-stack';
   @import 'loader';
   @import 'modal';
   @import 'pagination';
   @import 'product-card';
   @import 'profile-domains';
   @import 'profile-addon-products';
   @import 'profile-elements';
   @import 'profile-image';
   @import 'rating';
   @import 'table';
   @import 'tooltip';
   @import 'tooltip-complex';
   @import 'secondary-nav';
   @import 'slat';
   @import 'slider';
   @import 'stack';
   @import 'tertiary-nav';
   @import 'utility';
}
