.profile-checklist {
    padding-left: 24px;
    &--flush {
        padding-left: 0;
    }
    &__item {
        position: relative;
        margin-bottom: 6px;
        &:last-child {
            margin-bottom: 0;
        }
        &:before {
            font-family: AppDirectIcons;
            text-align: center;
            display: inline-block;
            width: 24px;
            content: " ";
            position: absolute;
            left: -24px;
        }
        &--checked:before {
            color: #6aaa1f;
            content: "\e003"
        }
    }
}

.profile-edition {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 3px;
    padding: 15px;
    height: 100%;

    &__header {
        text-align: center;
        & h3 {
            margin: 15px 0;
        }
    }
    &__prices {
        margin-bottom: 14px;
        text-align: center;
        &-separator {
            margin-left: 0;
            position: relative;
            text-transform: uppercase;
            z-index: 1;
            & span {
                font-size: 10px;
                display: inline-block;
                padding: 0 8px;
                background-color: #fff;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #eaeaea;
            }
        }
    }
    &__price {
        &:first-child .edition__price--primary-amount {
            font-size: 32px;
        }
        & .edition__price-frequency, & .edition__price-unit {
            text-transform: lowercase;
        }
    }
    &__price--primary {
        margin-top: 0;
        margin-left: 0;
        & .edition__price--primary-amount {
            font-size: 18px;
            font-weight: bold;
        }
        &:before {
            font-family: AppDirectIcons;
            text-align: center;
            display: inline-block;
            width: 24px;
            content: " ";
            position: absolute;
            left: -24px;
        }
    }
    &__action {
        text-align: center;
        margin-bottom: 14px;
    }
    &__details {
        border-bottom: 1px solid #eaeaea;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
    &__bullets {
        li:nth-child(n+8) {
            display: none;
        }
        .toggle-show,
        .toggle-hide {
            display: inline-block;
        }
        .toggle-hide {
            display: none;
            margin-top: 6px;
        }
        &.toggled {
            li:nth-child(n+8) {
                display: list-item;
            }
            .toggle-show {
                display: none;
            }
            .toggle-hide {
                display: inline-block;
            }
        }
    }
    &__footnotes {
        border-radius: 3px;
        margin-bottom: 24px;
        padding: 14px;
        background-color: #f5f5f5;
    }

    .profile-checklist__item.profile-edition__price {
        margin-left: 25px;
        margin-top: 10px;
        &:before {
            font-family: AppDirectIcons;
            content: "\e631";
            font-size: 10px;
            position: absolute;
            left: -25px;
            text-align: center;
            width: 19px;
        }
        &--primary, :first-child {
            margin-left: 0;
            margin-top: 0;
            &:before {
                content: " ";
            }
        }
        &--previous {
            text-decoration: line-through;
        }
    }
}

.profile-post--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    & .caption {
        white-space: normal;
    }
}

.reviews-summary {
    flex: 1 0 1px;
    display: flex;
    min-width: 65%;
    padding: 7px 0;
    &--average-rating, &--user-rating, &--no-rating {
        flex: 1 0 1px;
    }
    &--no-rating {
        padding: 7px 0;
        min-width: 60%;
        margin-bottom: 0;
    }
}


.profile__details {
    dl {
        margin: 0;
    }
}

.profile__demo {
    height: 150px;

    @include media-breakpoint-up(xs) {
        height: 250px;
    }

    @include media-breakpoint-up(md) {
        height: 350px;
    }
}
.profile__image {
    display: block;
    margin: 0 auto;
    width: auto;
    max-width: 100%;
    max-height: 500px;

}
.profile__image-list-control {
    flex: 0 0 44px;
}

.profile__image-list__item-content {
    position: relative;

    img{
        width: 100%;
        border: 1px solid #eee;
    }
}

.profile__image-list__item-overlay {
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    height: 44px;
    width: 44px;
    background-color: rgba(0,0,0,.3);
    color: #fff;
    --t-icon-stroke-primary: transparent;
    --t-icon-stroke-secondary: #ffffff;
    --t-icon-fill: #ffffff;
    border-radius: 22px;

    q2-icon {
        width: 44px;
        height: 44px;
    }
}

.profile__product {
    display: flex;

    .profile__content {
        flex: 1 0 calc(100% - 175px);
    }

    .profile__nav {
        flex: 0 0 175px;
    }

    @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
        
        .profile_content,
        .profile__nav {
            flex: 1 1 100%;
        }
    }
}

@include media-breakpoint-down(xs) {
    &.profile__image {
        margin-top: 14px;
    }
}

@include media-breakpoint-down(xm) {
    &.profile-content {
        padding-left: 12px;
        padding-right: 12px;
    }
    &.title__xxxxxlarge {
        font-size: 22px;
    }
    &.feature-actions {
        text-align: center;
    }
    &.featured-reviews {
        .slat--image_caption {
            font-size: 11px;
        }
    }
}

.profile-resources {
    &__item iframe {
        min-height: 340px;
        @include media-breakpoint-down(xm) {
            min-height: 200px;
        }
    }
}

.profile-entries {
    .slat {
        &--content {
            .subtitle {
                white-space: normal;
            }
            @include media-breakpoint-down(xm) {
                .badge {
                    white-space: normal;
                }
                .caption {
                    font-size: 11px;
                }
            }
        }
    }
}
