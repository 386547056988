#header-universal {
    min-height: 53px;
    background-color: #2d2a27;
}

.header {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
}

.header--item {
    display: inline-block;
    margin-bottom: 0;
    &:first-child {
        flex: 1 0 1px;
    }
}
