.badges {
    display: block;
    margin-top: 4px;
}

.badge {
    color: #fff;
    background-color: #fff;
    box-shadow: 0 0 0 1px #cccccc;
    border-radius: 2px;
    display: inline-block;
    font-size: 10px;
    line-height: 1em;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 4px;
    margin-right: 6px;
    margin-bottom: 6px;
    font-weight: 600;
    a {
        text-decoration: none;
    }
    &__outline {
        color: #767676;
        background: 0 0;
        a {
            color: #fff;
        }
    }
    &--emphasis {
        background-color: #006080;
        box-shadow: 0 0 0 1px #006080;
    }
    &--success {
        background-color: #76bd22;
        box-shadow: 0 0 0 1px #76bd22;
    }
    &--pending {
        box-shadow: 0 0 0 1px #dec117;
        background-color: #dec117;
    }
}

.is-fixed {
    .badge {
        display: none;
    }
}
