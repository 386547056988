.table--container {
    border: 1px solid #cbcbcb;
    background-color: #fff;
    border-radius: 3px;
    table {
        display: table;
        border-color: grey;
        font-size: 12px;
        line-height: 1.6em;
        min-width: 100%;
        text-align: left;
        border-collapse: collapse;
        border-spacing: 0;
    }
}

.table--content {
    background-color: #fff;
    background-image: linear-gradient(to right,#fff,rgba(255,255,255,0)),linear-gradient(to left,#fff,rgba(255,255,255,0)),linear-gradient(to right,#f5f5f5,rgba(245,245,245,0)),linear-gradient(to left,#f5f5f5,rgba(245,245,245,0));
    background-position: 0 0,100% 0,0 0,100% 0;
    background-repeat: no-repeat;
    background-size: 4em 100%,4em 100%,1em 100%,1em 100%;
    background-attachment: local,local,scroll,scroll;
    &:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
    &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    thead {
        font: bold 12px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #646464;
    }
    th, td {
        padding: 6px 12px;
    }
    th {
        font-weight: 400;
        text-align: left;
    }
    tbody tr {
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        &:first-child {
            border-top-color: #cbcbcb;
        }
    }
    .centered-content {
        text-align: center;
    }
}
